<div class="page-header mb-5 p-0" *ngIf="this.aplicationsFilter.length > 0">
  <div class="p-5">
    <form [formGroup]="form" (submit)="submit()">
      <h3 class="font-weight-bold pb-3">Pregled uporabnikov</h3>
      <div class="row col-sm-12 col-md-12 col-lg-12 pr-0 pl-0">
        <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
          <label class="small font-weight-bold" for="firstName"> Ime </label>
          <input type="text" id="firstName" class="form-control" formControlName="firstName">

          <div class="error-div-wrapper" *ngIf="this.validatorHelper.hasErrorAndTouched(form, 'firstName')">
            <ng-container [ngTemplateOutlet]="errorTemplate"
              [ngTemplateOutletContext]="{key:'firstName'}"></ng-container>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
          <label class="small font-weight-bold" for="lastName"> Priimek </label>
          <input type="text" class="form-control" formControlName="lastName">

          <div class="error-div-wrapper" *ngIf="this.validatorHelper.hasErrorAndTouched(form, 'lastName')">
            <ng-container [ngTemplateOutlet]="errorTemplate"
              [ngTemplateOutletContext]="{key:'lastName'}"></ng-container>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
          <label class="small font-weight-bold" for="email"> E-mail </label>
          <input type="text" class="form-control" formControlName="email">

          <div class="error-div-wrapper">
            <div *ngIf="this.validatorHelper.hasErrorAndTouched(form, 'email')">
              <ng-container [ngTemplateOutlet]="errorTemplate" [ngTemplateOutletContext]="{key:'email'}"></ng-container>
            </div>
            <div *ngIf="(form.invalid && form.touched) || form.dirty">
              <small *ngIf="form.controls['email'].errors?.['pattern']" class="invalid-feedback">Vnesite veljaven
                elektronski naslov</small>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
          <label class="small font-weight-bold" for="organisation"> Organizacija </label>
          <mat-select class="form-control" formControlName="organisation">
            <mat-option *ngFor="let company of companies" [value]="company.insuranceCompanyCode"> {{company.name}}
            </mat-option>
          </mat-select>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
          <label class="small font-weight-bold" for="certificateIssuerSubject"> Izdajatelj dig. potrdila </label>
          <mat-select class="form-control" formControlName="certificateIssuerSubject">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let certificateIssuer of certificateIssuers" [value]="certificateIssuer.subject">
              {{certificateIssuer.friendlyName}} </mat-option>
          </mat-select>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
          <label class="small font-weight-bold" for="certificateSerialNumber"> Serijska številka dig. potrdila </label>
          <input type="text" class="form-control" formControlName="certificateSerialNumber">

          <div class="error-div-wrapper"
            *ngIf="this.validatorHelper.hasErrorAndTouched(form, 'certificateSerialNumber')">
            <ng-container [ngTemplateOutlet]="errorTemplate"
              [ngTemplateOutletContext]="{key:'certificateSerialNumber'}"></ng-container>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-start ">
        <div class="col-sm-6 col-md-6 col-lg-8 col-xl-9">
          <label> <span class="small font-weight-bold">Aplikacija</span>
            <br />
            <div>
              <div class="btn-group row mt-1">
                <ng-container *ngFor="let application of this.aplicationsFilter; let i = index">
                  <label class="btn w-140 col-auto" [ngClass]="[application.isSelected ? ' btn-secondary': ' btn-grey',
                      application.applicationFriendlyName.length > 10 ? ' w-210': ' w-140']" btnCheckbox tabindex="0"
                    role="button" (click)="isApplicationSelected(application)">
                    <i *ngIf="application.isSelected" class="fas fa-check pr-1 i-filter-size"></i>
                    <span [ngClass]="[application.isSelected ? ' font-weight-bold color-black': 'font-color-grey']">
                      {{application.applicationFriendlyName}}
                    </span>
                  </label>
                </ng-container>
              </div>
            </div>
            <span *ngIf="!this.isApplicationButtonsValid()" class="invalid-feedback">
              Morate izbrati vsaj eno izmed ponujenih opcij.
            </span>
          </label>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 pl-xl-0 mt-1">
          <label></label><br>
          <button class="btn btn-primary mr-2" type="submit">Išči</button>
          <button class="btn btn-primary" (click)="this.goToUserPage()" type="button">Dodaj uporabnika</button>
        </div>
      </div>
      <ng-template #errorTemplate let-key="key">
        <div *ngFor="let error of this.validatorHelper.getFormValidationErrors(form, key)" class="invalid-feedback">
          {{error.value}} </div>
      </ng-template>
    </form>
  </div>
</div>

<szz-loader-search-in-progress [searchInProgress]="searchInProgress"></szz-loader-search-in-progress>

<ng-container *ngIf="searchInProgress != null && !searchInProgress && this.users && !this.isErrorOccured">
  <div class="pr-5 pl-5" *ngIf="this.users.length == 0">
    <div class="card rounded border-0">
      <div class="row align-items-center ">
        <div class="col text-center p-5">
          <h5 class="mb-1">Za navedene iskalne kriterije ni zadetkov! </h5>
        </div>
      </div>
    </div>
  </div>

<div class="container-fluid pl-2 pr-2" *ngIf="this.users.length !== 0">
  <div class="card rounded border-0 p-3">
    <div class="d-flex justify-content-end pb-2" *ngIf="showExportButton">
        <div class="dropdown" dropdown>
          <a class="dropdown-toggle btn btn-primary" data-toggle="dropdown" href="javascript: void(0)"
            dropdownToggle>
            <i class="fas fa-file-export"></i> Izvozi
          </a>
          <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
            <li class="border-bottom p-2" style="width: 220px;">
              <a (click)="downloadEmails()">
                <i class="fas fa-envelope pl-2 pr-2" style="font-size: 20px; color: #8e4058;"></i>
                Samo email naslove
              </a>
            </li>
            <li class="p-2">
              <a (click)="downloadTable()">
                <i class="fas fa-table pl-2 pr-2" style="font-size: 20px; color: #8e4058;"></i>
                Celoten seznam
              </a>
            </li>
          </ul>
        </div>
    </div>
    <szz-user-table [users]='this.users'></szz-user-table>
  </div>
</div>
</ng-container>
