import { Component, OnInit, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { UserService } from 'src/app/data/api/services';
import { DigitalCertificate } from '../../data/api/models/digital-certificate';;
import { ToastrService } from 'ngx-toastr';
import { SzzValidators } from '../../common/szz-validators';
import { ValidatorHelper } from '../../common/validator-helper';
import { UserCommonService } from '../../common/user-common.service';
import { CommonApiService } from 'src/app/data/api/services/common-api.service';
import { CertificateIssuer } from '../../data/api/models/certificate-issuer';

@Component({
  selector: 'szz-digital-certificate',
  templateUrl: './digital-certificate.component.html',
  styleUrls: ['./digital-certificate.component.scss'],
})
export class DigitalCertificateComponent implements OnInit {
  form: UntypedFormGroup = new UntypedFormGroup({});
  digitalCertificate: DigitalCertificate = {};
  @Input() status = '';

  data = {
    userId: '',
    digitalCertificate: this.digitalCertificate,
    status: '',
  };

  certificateIssuers: Array<CertificateIssuer> = [];

  constructor(
    private modalService: BsModalService,
    private userService: UserService,
    private toastr: ToastrService,
    public validatorHelper: ValidatorHelper,
    private userCommonService: UserCommonService,
    private commonApiService: CommonApiService) {}

  ngOnInit(): void {
    this.initForm();
  }

  submit() {
    this.form.markAllAsTouched();
    this.form.markAsDirty();

    if (this.form.valid) {
      let formValues = this.form.getRawValue();
      if (this.data.status === 'insert') {
        let request: DigitalCertificate = {
          comment: formValues.comment,
          isDisabled: formValues.isDisabled,
          modifiedDate: new Date().toISOString(),
          publisher: formValues.publisher,
          serialNumber: formValues.serialNumber,
        };
        this.userCommonService.setDigCertificate(request);
      } else {
        let request: DigitalCertificate = {
          comment: formValues.comment,
          isDisabled: formValues.isDisabled,
          modifiedDate: new Date().toISOString(),
          publisher: formValues.publisher,
          serialNumber: formValues.serialNumber,
          digitalCertificateId: this.data?.digitalCertificate?.digitalCertificateId,
        };
        this.userCommonService.setDigCertificate(request);
      }
    }

    this.modalService.setDismissReason('SAVE_RESULT');
    this.modalService.hide();
  }

  initForm() {
    this.form = new UntypedFormGroup({
      publisher: new UntypedFormControl(this.data?.digitalCertificate?.publisher ? this.data?.digitalCertificate?.publisher : ''),
      serialNumber: new UntypedFormControl(this.data?.digitalCertificate?.serialNumber ? this.data?.digitalCertificate?.serialNumber : '',
        [SzzValidators.maxLengthValidator(50, 'Vnesite največ 50 znakov!')]
      ),
      comment: new UntypedFormControl(this.data?.digitalCertificate?.comment ? this.data?.digitalCertificate?.comment : '',
        [SzzValidators.maxLengthValidator(200, 'Vnesite največ 200 znakov!')]
      ),
      isDisabled: new UntypedFormControl(this.data?.digitalCertificate?.isDisabled ? this.data?.digitalCertificate?.isDisabled : false),
    });

    this.commonApiService.getCertificateIssuers({})
    .subscribe({
      next: (x) => { this.certificateIssuers = x; },
      error: (e) => { throw e; },
    });
  }

  close() {
    this.modalService.setDismissReason('CLOSE');
    this.modalService.hide();
  }
}
