import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'szz-activate-deactivate',
  templateUrl: './activate-deactivate.component.html',
  styleUrls: ['./activate-deactivate.component.scss']
})
export class ActivateDeactivateComponent implements OnInit {

  data = {
    title: '',
    okLabel: '',
    closeLabel: "",
    message: "",
  };

  constructor(private modalService: BsModalService) {}

  ngOnInit(): void {}

  confirm(): void {
    this.modalService.setDismissReason("OK");
    this.modalService.hide();
  }

  close() {
    this.modalService.setDismissReason("CLOSE");
    this.modalService.hide();
  }
}
