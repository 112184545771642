/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CertificateIssuer } from '../models/certificate-issuer';
import { UserBasic } from '../models/user-basic';
@Injectable({
  providedIn: 'root',
})
class CommonApiService extends __BaseService {
  static readonly getCertificateIssuersPath = '/commonapi/certificate_issuers';
  static readonly getUserPath = '/commonapi/user';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CommonApiService.GetCertificateIssuersParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  getCertificateIssuersResponse(params: CommonApiService.GetCertificateIssuersParams): __Observable<__StrictHttpResponse<Array<CertificateIssuer>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/commonapi/certificate_issuers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CertificateIssuer>>;
      })
    );
  }
  /**
   * @param params The `CommonApiService.GetCertificateIssuersParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  getCertificateIssuers(params: CommonApiService.GetCertificateIssuersParams): __Observable<Array<CertificateIssuer>> {
    return this.getCertificateIssuersResponse(params).pipe(
      __map(_r => _r.body as Array<CertificateIssuer>)
    );
  }

  /**
   * @param params The `CommonApiService.GetUserParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `keycloakId`:
   *
   * - `emial`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  getUserResponse(params: CommonApiService.GetUserParams): __Observable<__StrictHttpResponse<UserBasic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.keycloakId != null) __params = __params.set('keycloakId', params.keycloakId.toString());
    if (params.emial != null) __params = __params.set('emial', params.emial.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/commonapi/user`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserBasic>;
      })
    );
  }
  /**
   * @param params The `CommonApiService.GetUserParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `keycloakId`:
   *
   * - `emial`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  getUser(params: CommonApiService.GetUserParams): __Observable<UserBasic> {
    return this.getUserResponse(params).pipe(
      __map(_r => _r.body as UserBasic)
    );
  }
}

module CommonApiService {

  /**
   * Parameters for getCertificateIssuers
   */
  export interface GetCertificateIssuersParams {

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for getUser
   */
  export interface GetUserParams {
    userId?: number;
    keycloakId?: string;
    emial?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }
}

export { CommonApiService }
