import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'szz-campaign-history',
  templateUrl: './campaign-history.component.html',
  styleUrls: ['./campaign-history.component.scss'],
})
export class CampaignHistoryComponent implements OnInit {
  data = {
    okLabel: '',
    closeLabel: ''
  };

  history = [
    {
      user: { fullName: 'Dragana Savanovic' },
      created: new Date(),
      id: "1"
    },
    {
      user: { fullName: 'Dragana Savanovic' },
      created: new Date(),
      id: "2"
    }
  ];

  constructor(private modalService: BsModalService) {}

  ngOnInit(): void {}

  close() {
    this.modalService.setDismissReason('CLOSE');
    // this.modalCloseService.hide();
    this.modalService.hide();
  }

  confirm(){

  }
}
