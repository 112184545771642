<div class="container-fluid">
  <h1 class="display-1">{{errorCode}}</h1>
  <!-- <p class="lead">{{message}} </p> -->

  <div class="lead" [innerHTML]="message"></div>
  <p>Lahko se
    <a href="javascript:history.back()">vrnete nazaj</a>
    na prejšnjo stran ali pa na
    <a [routerLink]="['/']">prvo stran</a>.</p>
</div>
