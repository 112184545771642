import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UserService, CodeTableService, CommonApiService } from 'src/app/data/api/services';
import { tap } from 'rxjs/operators';
import { User } from '../../../data/api/models/user';
import { InsuranceCompany } from '../../../data/api/models/insurance-company';
import { Application, CertificateIssuer } from 'src/app/data/api/models';
import { Router } from '@angular/router';
import { SzzValidators } from '../../../common/szz-validators';
import { ValidatorHelper } from '../../../common/validator-helper';
import { UserCommonService } from '../../../common/user-common.service';
import { VALIDATION_PATTERNS } from '../../../common/_patterns';

@Component({
  selector: "szz-codes",
  templateUrl: "./codes.component.html",
  styleUrls: ["./codes.component.scss"],
})
export class CodesComponent implements OnInit {
  searchInProgress: boolean | null = null;
  form: UntypedFormGroup = new UntypedFormGroup({});
  dataArray = [];
  users: Array<User> = [];
  user: User = {};
  companies: Array<InsuranceCompany> = [];
  certificateIssuers: Array<CertificateIssuer> = [];
  isErrorOccured = false;
  showExportButton: boolean = false;
  organisations = [
    { key: "chooseOrganisation", value: "Izaberi organizacijo" },
  ];

  aplicationsFilter: any[] = [];
  applications: any[] = [];
  applicationsArray: any[] = [];

  constructor(
    private userService: UserService,
    private router: Router,
    public validatorHelper: ValidatorHelper,
    private codeTableService: CodeTableService,
    private userCommonService: UserCommonService,
    private commonApiService: CommonApiService
  ) {
    this.getAvailableCompaniesForUser();
  }

  ngOnInit(): void {
    this.initForm();

    this.users = this.userCommonService.getUsers();
    if (this.users.length > 0) {
      this.searchInProgress = false;
    }

    this.user = this.userCommonService.getUserReq();
    if (this.user) {
      this.form.patchValue({
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
        organisation: this.user.insuranceCompanyCode,
      });
    }

    this.aplicationsFilter = this.userCommonService.getApplicationsFilter();

    if (this.aplicationsFilter.length === 0) {
      this.codeTableService
        .AllowedApplications({})
        .subscribe((applications) => {
          this.applications = applications;
          applications.forEach((x) => {
            this.aplicationsFilter.push({ ...x, isSelected: true });
          });
        });
    }
  }

  initForm() {
    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl("", [
        SzzValidators.maxLengthValidator(30, "Vnesite največ 30 znakov!"),
      ]),
      lastName: new UntypedFormControl("", [
        SzzValidators.maxLengthValidator(50, "Vnesite največ 50 znakov!"),
      ]),
      insuranceCompanyCode: new UntypedFormControl("SLO-01"),
      email: new UntypedFormControl("", [
        SzzValidators.maxLengthValidator(50, "Vnesite največ 50 znakov!"),
        Validators.pattern(VALIDATION_PATTERNS.EMAIL),
      ]),
      organisation: new UntypedFormControl(""),
      certificateIssuerSubject: new UntypedFormControl(""),
      certificateSerialNumber: new UntypedFormControl(
        { value: "", disabled: true },
        [
          SzzValidators.requiredValidator("Serijska številka je obvezna!"),
          SzzValidators.maxLengthValidator(50, "Vnesite največ 50 znakov!"),
        ]
      ),
    });

    this.form.get("certificateIssuerSubject")?.valueChanges.subscribe((x) => {
      const certificateSerialNumber = this.form.get("certificateSerialNumber")!;
      if (x) {
        certificateSerialNumber.enable();
      } else {
        certificateSerialNumber.disable();
        certificateSerialNumber.setValue("");
      }
      certificateSerialNumber.updateValueAndValidity();
    });

    this.commonApiService.getCertificateIssuers({}).subscribe({
      next: (x) => {
        this.certificateIssuers = x;
      },
      error: (e) => {
        throw e;
      },
    });
  }

  submit() {
    this.filterAplicationData();
    let formValues = this.form.getRawValue();

    let request = {
      firstName: formValues.firstName ?? "",
      lastName: formValues.lastName ?? "",
      insuranceCompanyCode: formValues.organisation ?? "",
      email: formValues.email ?? "",
      applications:
        this.aplicationsFilter.length === this.applicationsArray.length
          ? []
          : this.applicationsArray,
      certificateIssuerSubject: formValues.certificateIssuerSubject ?? "",
      certificateSerialNumber: formValues.certificateSerialNumber ?? "",
    };

    this.userCommonService.setUserReq(request);

    if (this.searchInProgress) {
      return;
    }

    this.form.markAllAsTouched();
    this.form.markAsDirty();

    if (this.form.valid && this.isApplicationButtonsValid()) {
      this.searchInProgress = true;
      this.userService
        .userSearch(request)
        .subscribe({
          next: (users) => {
            this.searchInProgress = false;
            this.users = users;
            this.showExportButton = this.users.some(user => user.isUserActive);
            this.userCommonService.setUsers(this.users);
            this.isErrorOccured = false;
          },
          error: (e) => {
            this.isErrorOccured = true;
            this.searchInProgress = false;
            throw e;
          },
        });
    }
  }

  filterAplicationData() {
    this.applicationsArray = this.aplicationsFilter.map((x: any) =>
      x.isSelected ? x.applicationName : null
    );
    this.applicationsArray = this.applicationsArray.filter(
      (x: any) => x !== null
    );
  }

  isApplicationSelected(application: Application) {
    this.aplicationsFilter.map((x: any) => {
      if (x.applicationName === application.applicationName) {
        x.isSelected = !x.isSelected;
      }
    });
    this.userCommonService.setApplicationsFilter(this.aplicationsFilter);
  }

  isApplicationButtonsValid() {
    let isSelected = this.aplicationsFilter.some((x) => x.isSelected);
    if (isSelected) {
      return true;
    } else {
      return false;
    }
  }

  goToUserPage() {
    this.router.navigate(["/add-user"]);
  }

  getAvailableCompaniesForUser() {
    this.userService
      .AvailableCompaniesForUser({})
      .pipe(
        tap((x) => {
          this.companies = x;
          if (this.companies.length === 1) {
            this.form.patchValue({
              organisation: this.companies[0].insuranceCompanyCode,
            });
          }
        })
      )
      .subscribe({
        next: (v) => {},
        error: (e) => {
          throw e;
        },
      });
  }

  downloadEmails() {
    const csvContent =
      this.users
        .filter((user) => user.isUserActive)
        .map((x) => x.email)
        .join(";") + ";";
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "email-list.csv";
    anchor.click();

    window.URL.revokeObjectURL(url);
  }

  downloadTable() {
    const header = "Ime in priimek\tEmail\tOrganizacija\tAplikacije\n";

    const rows = this.users
      .filter((user) => user.isUserActive)
      .map(
        (user) =>
          `${user.firstName + " " + user.lastName}\t${user.email}\t${
            user.insuranceCompanyName
          }\t${user.applications?.join(", ")}`
      )
      .join("\n");

    const tsvContent = header + rows;

    const blob = new Blob([tsvContent], {
      type: "text/tab-separated-values;charset=utf-8;",
    });
    const url = window.URL.createObjectURL(blob);

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "users-list.csv";
    anchor.click();

    window.URL.revokeObjectURL(url);
  }
}
