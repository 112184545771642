<div id="inactivityModal" role="dialog" tabindex="-1" aria-labelledby="dialogInactivityModalLabel" aria-hidden="true">
  <div class="modal-content" role="document">
    <div class="modal-header">
      <h5 class="modal-title">Opozorilo</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body bg-light">
      <p>V primeru daljše neaktivnosti vas Frodo iz varnostnih razlogov odjavi iz sistema. V kolikor želite nadaljevati
        delo s sistemom, izberite gumb <b>Ostani prijavljen</b>, sicer boste avtomatično odjavljeni čez 1 minuto. </p>
    </div>
    <div class="modal-body bg-light">
      <div class="floated-button-div">
        <button type="button" class="btn btn-primary" (click)="logout()">Odjava</button>
      </div>
      <div class="floated-button-div">
        <button type="button" class=" btn btn-primary" (click)="close()">Ostani prijavljen</button>
      </div>
    </div>
