<div class="modal-header">
  <h5 class="modal-title">Brisanje</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
  </button>
</div>

<div class="modal-body">
     {{data.message}}
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="confirm()">{{data.okLabel}}</button>
  <button type="button" class="btn btn-secondary" (click)="close()">{{data.closeLabel}}</button>
</div>
