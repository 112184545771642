import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CodesComponent } from './codes/codes.component';
import { ComponentsModule } from '../../components/components.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BootstrapModule } from '../../common/bootstrap.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [CodesComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatListModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    BrowserAnimationsModule,
    BootstrapModule,
    ComponentsModule,
    ButtonsModule.forRoot(),
  ],
  exports: [CodesComponent, ComponentsModule],
})
export class CodesModule {}
