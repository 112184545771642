import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'szz-user-overview',
  templateUrl: './user-overview.component.html',
  styleUrls: ['./user-overview.component.scss']
})
export class UserOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {}

}
