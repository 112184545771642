/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { User } from '../models/user';
import { CertificateIssuer } from '../models/certificate-issuer';
import { InsuranceCompany } from '../models/insurance-company';
@Injectable({
  providedIn: 'root',
})
class UserService extends __BaseService {
  static readonly activateOrDeactivateUserPath = '/user/activateordeactivateuser';
  static readonly addOrUpdateUserPath = '/user/addorupdateuser';
  static readonly getCertificateIssuerPath = '/user/certificateissuer';
  static readonly AvailableCompaniesForUserPath = '/user/companies';
  static readonly userDetailsPath = '/user/details';
  static readonly userSearchPath = '/user/search';
  static readonly SyncAllUsersWithKeycloakPath = '/user/sync';
  static readonly updateUserPasswordPath = '/user/updateuserpassword';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `UserService.ActivateOrDeactivateUserParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `isUserActive`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  activateOrDeactivateUserResponse(params: UserService.ActivateOrDeactivateUserParams): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.isUserActive != null) __params = __params.set('isUserActive', params.isUserActive.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/user/activateordeactivateuser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param params The `UserService.ActivateOrDeactivateUserParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `isUserActive`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  activateOrDeactivateUser(params: UserService.ActivateOrDeactivateUserParams): __Observable<User> {
    return this.activateOrDeactivateUserResponse(params).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param params The `UserService.AddOrUpdateUserParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  addOrUpdateUserResponse(params: UserService.AddOrUpdateUserParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/user/addorupdateuser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.AddOrUpdateUserParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  addOrUpdateUser(params: UserService.AddOrUpdateUserParams): __Observable<null> {
    return this.addOrUpdateUserResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserService.GetCertificateIssuerParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  getCertificateIssuerResponse(params: UserService.GetCertificateIssuerParams): __Observable<__StrictHttpResponse<Array<CertificateIssuer>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user/certificateissuer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CertificateIssuer>>;
      })
    );
  }
  /**
   * @param params The `UserService.GetCertificateIssuerParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  getCertificateIssuer(params: UserService.GetCertificateIssuerParams): __Observable<Array<CertificateIssuer>> {
    return this.getCertificateIssuerResponse(params).pipe(
      __map(_r => _r.body as Array<CertificateIssuer>)
    );
  }

  /**
   * @param params The `UserService.AvailableCompaniesForUserParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  AvailableCompaniesForUserResponse(params: UserService.AvailableCompaniesForUserParams): __Observable<__StrictHttpResponse<Array<InsuranceCompany>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user/companies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<InsuranceCompany>>;
      })
    );
  }
  /**
   * @param params The `UserService.AvailableCompaniesForUserParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  AvailableCompaniesForUser(params: UserService.AvailableCompaniesForUserParams): __Observable<Array<InsuranceCompany>> {
    return this.AvailableCompaniesForUserResponse(params).pipe(
      __map(_r => _r.body as Array<InsuranceCompany>)
    );
  }

  /**
   * @param params The `UserService.UserDetailsParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  userDetailsResponse(params: UserService.UserDetailsParams): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user/details`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param params The `UserService.UserDetailsParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  userDetails(params: UserService.UserDetailsParams): __Observable<User> {
    return this.userDetailsResponse(params).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param params The `UserService.UserSearchParams` containing the following parameters:
   *
   * - `lastName`:
   *
   * - `insuranceCompanyCode`:
   *
   * - `firstName`:
   *
   * - `email`:
   *
   * - `certificateSerialNumber`:
   *
   * - `certificateIssuerSubject`:
   *
   * - `applications`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  userSearchResponse(params: UserService.UserSearchParams): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.lastName != null) __params = __params.set('lastName', params.lastName.toString());
    if (params.insuranceCompanyCode != null) __params = __params.set('insuranceCompanyCode', params.insuranceCompanyCode.toString());
    if (params.firstName != null) __params = __params.set('firstName', params.firstName.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    if (params.certificateSerialNumber != null) __params = __params.set('certificateSerialNumber', params.certificateSerialNumber.toString());
    if (params.certificateIssuerSubject != null) __params = __params.set('certificateIssuerSubject', params.certificateIssuerSubject.toString());
    (params.applications || []).forEach(val => {if (val != null) __params = __params.append('applications', val.toString())});
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * @param params The `UserService.UserSearchParams` containing the following parameters:
   *
   * - `lastName`:
   *
   * - `insuranceCompanyCode`:
   *
   * - `firstName`:
   *
   * - `email`:
   *
   * - `certificateSerialNumber`:
   *
   * - `certificateIssuerSubject`:
   *
   * - `applications`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  userSearch(params: UserService.UserSearchParams): __Observable<Array<User>> {
    return this.userSearchResponse(params).pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * @param params The `UserService.SyncAllUsersWithKeycloakParams` containing the following parameters:
   *
   * - `insuranceCompanyCode`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  SyncAllUsersWithKeycloakResponse(params: UserService.SyncAllUsersWithKeycloakParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.insuranceCompanyCode != null) __params = __params.set('insuranceCompanyCode', params.insuranceCompanyCode.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user/sync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.SyncAllUsersWithKeycloakParams` containing the following parameters:
   *
   * - `insuranceCompanyCode`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  SyncAllUsersWithKeycloak(params: UserService.SyncAllUsersWithKeycloakParams): __Observable<null> {
    return this.SyncAllUsersWithKeycloakResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserService.UpdateUserPasswordParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  updateUserPasswordResponse(params: UserService.UpdateUserPasswordParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/user/updateuserpassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.UpdateUserPasswordParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  updateUserPassword(params: UserService.UpdateUserPasswordParams): __Observable<null> {
    return this.updateUserPasswordResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UserService {

  /**
   * Parameters for activateOrDeactivateUser
   */
  export interface ActivateOrDeactivateUserParams {
    userId?: number;
    isUserActive?: boolean;

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for addOrUpdateUser
   */
  export interface AddOrUpdateUserParams {
    body?: User;

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for getCertificateIssuer
   */
  export interface GetCertificateIssuerParams {

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for AvailableCompaniesForUser
   */
  export interface AvailableCompaniesForUserParams {

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for userDetails
   */
  export interface UserDetailsParams {
    userId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for userSearch
   */
  export interface UserSearchParams {
    lastName?: string;
    insuranceCompanyCode?: string;
    firstName?: string;
    email?: string;
    certificateSerialNumber?: string;
    certificateIssuerSubject?: string;
    applications?: Array<string>;

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for SyncAllUsersWithKeycloak
   */
  export interface SyncAllUsersWithKeycloakParams {
    insuranceCompanyCode?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for updateUserPassword
   */
  export interface UpdateUserPasswordParams {
    userId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }
}

export { UserService }
