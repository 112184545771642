import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalCloseService } from '../../common/modal-close.service';

@Component({
  selector: 'szz-inactivity',
  templateUrl: './inactivity.component.html',
  styleUrls: ['./inactivity.component.scss']
})
export class InactivityComponent implements OnInit {

  isAuthenticated: boolean = true;
  private logoutRouterLink: string = "/api/auth/logout?returnUrl=";

  constructor(private modalService: BsModalService, private modalCloseService : ModalCloseService) { }

  ngOnInit(): void {}

   close() {
      this.modalService.setDismissReason("CLOSE");
      this.modalCloseService.hide();
     }
    logout() {
      this.isAuthenticated = false;
      window.location.replace(this.logoutRouterLink + encodeURIComponent(window.location.href));
    }
}
