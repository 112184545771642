import { LoggingService } from './common/logging.service';
import { ErrorService } from './common/error.service';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector ) { }

  handleError(error: HttpErrorResponse) {
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingService);
    let message;
    let status;
    errorService.hideToastr();
    if (error instanceof HttpErrorResponse) {
      // Server error
      message = errorService.getServerErrorMessage(error);
      status = error.status;
    } else {
      // Client Error
      message = "Page not found.";
      status = 404;
    }
    errorService.handleError(error);
    logger.logError(error);
  }
}
