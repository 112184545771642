<div id="inactivityModal" role="dialog" tabindex="-1" aria-labelledby="dialogInactivityModalLabel" aria-hidden="true">
  <div class="modal-content" role="document">
    <div class="modal-header">
      <h5 class="modal-title">Dodaj digitalno potrdilo
      </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form [formGroup]="form" (submit)="submit()">
      <div class="modal-body bg-light">
        <div class="m-3">
          <div class="row">
            <label class="small font-weight-bold" for="publisher"> Izdajatelj </label>
            <mat-select class="form-control"  formControlName="publisher">
              <mat-option *ngFor="let item of this.certificateIssuers" [value]="item.subject"> {{item.friendlyName}} </mat-option>
            </mat-select>
          </div>
          <div class="mt-3">
            <div class="row">
              <label class="small font-weight-bold" for="serialNumber"> Serijska številka </label>
              <input type="text" class="form-control" formControlName="serialNumber">
            </div>
            <div class="row">
              <div class="p-0 col-xl-12 col-lg-12 d-sm-none d-md-none d-xl-block d-lg-block small">
                <div *ngIf="this.validatorHelper.hasError(form, 'serialNumber')">
                  <ng-container [ngTemplateOutlet]="errorTemplate" [ngTemplateOutletContext]="{key:'serialNumber'}"></ng-container>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="row">
              <label class="small font-weight-bold" for="comment"> Komentar </label>
              <textarea type="text" class="form-control" formControlName="comment"></textarea>
            </div>

            <div class="row">
              <div class=" p-0 col-xl-12 col-lg-12 d-sm-none d-md-none d-xl-block d-lg-block small">
                <div *ngIf="this.validatorHelper.hasError(form, 'comment')">
                  <ng-container [ngTemplateOutlet]="errorTemplate"
                                [ngTemplateOutletContext]="{key:'comment'}"></ng-container>
                </div>
              </div>
            </div>
          </div>

          <div class="row pt-4">
            <section class="example-section">
              <mat-checkbox class="example-margin checkbox-box" formControlName="isDisabled">Onemogočeno</mat-checkbox>
            </section>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class=" btn btn-link" (click)="close()">Prekliči</button>
        <button type="submit" class=" btn btn-primary">Shrani</button>
      </div>

      <ng-template #errorTemplate let-key="key">
        <div *ngFor="let error of this.validatorHelper.getFormValidationErrors(form, key)"
             class="invalid-feedback"> {{error.value}} </div>
      </ng-template>
    </form>
  </div>
</div>
