/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Role } from '../models/role';
import { Application } from '../models/application';
@Injectable({
  providedIn: 'root',
})
class CodeTableService extends __BaseService {
  static readonly AllowedRolesPath = '/codetable/allowed-roles';
  static readonly AllowedApplicationsPath = '/codetable/allowed_applications';
  static readonly ApplicationsPath = '/codetable/applications';
  static readonly RolesPath = '/codetable/roles';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CodeTableService.AllowedRolesParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  AllowedRolesResponse(params: CodeTableService.AllowedRolesParams): __Observable<__StrictHttpResponse<Array<Role>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/codetable/allowed-roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Role>>;
      })
    );
  }
  /**
   * @param params The `CodeTableService.AllowedRolesParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  AllowedRoles(params: CodeTableService.AllowedRolesParams): __Observable<Array<Role>> {
    return this.AllowedRolesResponse(params).pipe(
      __map(_r => _r.body as Array<Role>)
    );
  }

  /**
   * @param params The `CodeTableService.AllowedApplicationsParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  AllowedApplicationsResponse(params: CodeTableService.AllowedApplicationsParams): __Observable<__StrictHttpResponse<Array<Application>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/codetable/allowed_applications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Application>>;
      })
    );
  }
  /**
   * @param params The `CodeTableService.AllowedApplicationsParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  AllowedApplications(params: CodeTableService.AllowedApplicationsParams): __Observable<Array<Application>> {
    return this.AllowedApplicationsResponse(params).pipe(
      __map(_r => _r.body as Array<Application>)
    );
  }

  /**
   * @param params The `CodeTableService.ApplicationsParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  ApplicationsResponse(params: CodeTableService.ApplicationsParams): __Observable<__StrictHttpResponse<Array<Application>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/codetable/applications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Application>>;
      })
    );
  }
  /**
   * @param params The `CodeTableService.ApplicationsParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  Applications(params: CodeTableService.ApplicationsParams): __Observable<Array<Application>> {
    return this.ApplicationsResponse(params).pipe(
      __map(_r => _r.body as Array<Application>)
    );
  }

  /**
   * @param params The `CodeTableService.RolesParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  RolesResponse(params: CodeTableService.RolesParams): __Observable<__StrictHttpResponse<Array<Role>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/codetable/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Role>>;
      })
    );
  }
  /**
   * @param params The `CodeTableService.RolesParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return Success
   */
  Roles(params: CodeTableService.RolesParams): __Observable<Array<Role>> {
    return this.RolesResponse(params).pipe(
      __map(_r => _r.body as Array<Role>)
    );
  }
}

module CodeTableService {

  /**
   * Parameters for AllowedRoles
   */
  export interface AllowedRolesParams {

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for AllowedApplications
   */
  export interface AllowedApplicationsParams {

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for Applications
   */
  export interface ApplicationsParams {

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for Roles
   */
  export interface RolesParams {

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }
}

export { CodeTableService }
