
  <div class="row">
    <div class="col-11 pl-0 pr-0">
      <div *ngIf="title && options.enableHtml" [class]="options.titleClass" role="alert" aria-live="polite" [attr.aria-label]="title">
        {{ title }}
      </div>
      <div *ngIf="message && options.enableHtml && !this.showFullMessage"
           role="alert" aria-live="polite" [class]="options.messageClass" [attr.aria-label]="message">
           {{ this.convert.manipulateWithErrorText(message) }}
      </div>

      <div *ngIf="message && options.enableHtml && this.showFullMessage"
           role="alert" aria-live="polite" [class]="options.messageClass" [attr.aria-label]="message">
           {{ message }}
      </div>

      <div *ngIf="message && message.length > 110 && options.enableHtml" class="font-weight-bold d-flex justify-content-between">
        <button  class="btn btn-link pl-0" type="button" (click)="showMessageDetails()">
          <span *ngIf="!this.showFullMessage">Prikaži podrobnosti</span>
          <span *ngIf="this.showFullMessage">Skrij podrobnosti</span>
        </button>

        <button  class="btn btn-link pl-0" type="button" (click)="copyToClipboard(message)">
          <!-- <i class="fas fa-paste"  *ngIf="!this.isCopiedToClipboard"  [tooltip]="clipboardTooltip"  placement="top"></i>
          <i class="fas fa-paste" style="color: #c6c6c7;" *ngIf="this.isCopiedToClipboard" ></i> -->
          <span *ngIf="!this.isCopiedToClipboard"> <i class="fas fa-paste" style="font-size: 18px;" ></i> Kopiraj opis napake</span>
          <span *ngIf="this.isCopiedToClipboard"><i class="fas fa-paste" style="color: #c6c6c7; font-size: 18px;" ></i> Opis skopiran v odložišče</span>
        </button>
      </div>
    </div>
    <div class="col-1 text-right">
      <i class="fas fa-times-circle" style="font-size: 25px;" *ngIf="options.closeButton" (click)="remove()"></i>
    </div>
  </div>
