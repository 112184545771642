import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../data/api/models/user';
import { Convert } from '../../common/convert';

@Component({
  selector: 'szz-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss'],
})
export class UserTableComponent implements OnInit {
  @Input() users: Array<User> = [];
  pageDataArray: Array<User> = [];

  constructor(private router: Router, public convert: Convert) {}

  ngOnInit(): void {}

  onChangePage(pageOfItems: Array<User>) {
    this.pageDataArray = pageOfItems;
  }

  manipulateApplicationText(application: string) {
    let firstLetterUpper = application.charAt(0).toUpperCase() + application.substring(1).toLowerCase();
    if (firstLetterUpper.split('_')[0] && firstLetterUpper.split('_')[1]) {
      let splitedText = firstLetterUpper.split('_')[0] + ' ' + firstLetterUpper.split('_')[1];
      return splitedText;
    } else {
      return firstLetterUpper;
    }
  }

  goToUserDetails(user: User) {
    this.router.navigate(['/user-details', user.userId]);
  }
}
