
import { Component, OnInit } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { Convert } from '../../common/convert';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: "szz-error-toastr",
  templateUrl: "./error-toastr.component.html",
  styleUrls: ["./error-toastr.component.scss"],
})
export class ErrorToastrComponent extends Toast implements OnInit {
  showFullMessage = false;
  isCopiedToClipboard = false;

  constructor(
    public override toastrService: ToastrService,
    public override toastPackage: ToastPackage,
    public convert: Convert,
    private clipboardService: ClipboardService
  ) {
    super(toastrService, toastPackage);
  }

  ngOnInit(): void {}

  showMessageDetails() {
    this.showFullMessage = !this.showFullMessage;
  }

  copyToClipboard(text: string) {
    this.clipboardService.copy(text);
    this.isCopiedToClipboard = true;

    setTimeout(() => {
      this.isCopiedToClipboard = false;
    }, 3000);
  }
}
