<div id="inactivityModal"
     role="dialog"
     tabindex="-1"
     aria-labelledby="dialogInactivityModalLabel"
     aria-hidden="true"
     class="">
  <div class="modal-content"
       role="document">
    <div class="modal-header">
      <h6 class="modal-title"><span class="font-weight-bold">Dodaj digitalno potdrilo</span></h6>
      <button type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-container *ngFor="let item of this.history">
        <div class="row history m-2 pb-2">
          <div class="pr-0 pl-0 col-4 border-right">{{item.created | date:'dd.MM.yyyy HH:mm:ss'}} </div>
          <div class="col-8 pr-0 pl-3">
            <div *ngIf="item.id === '1'"> {{item.user.fullName}} je spremenil status reševanja iz </div>
            <div *ngIf="item.id === '2'"> {{item.user.fullName}} je dodelil uporabnika </div>
            <!--  <div> {{item.user?.fullName}} je odstranil uporabnika </div>
            <div> Izvedeno je bilo preverjanje škodnega spisa </div>
            <div> Spremenjena vidnost komentarja </div>
            <div> Izbrisan komentar </div>
            <div> Spremenjena vsebina komentarja </div>
            <div> Dodan komentar (viden znotraj zavarovalnice) </div>
            <div> Dodan komentar (viden vsem uporabnikom) </div> -->
          </div>
        </div>
      </ng-container>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-link" (click)="close()">{{data.closeLabel}}</button>
      <button type="button" class="btn btn-primary" (click)="confirm()">{{data.okLabel}}</button>

    </div>
  </div>
</div>
