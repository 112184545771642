<ng-container *ngIf="!searchInProgress">
  <form [formGroup]="form" (submit)="submit()" >
    <div class="card m-2" style="position: relative; align-content: center !important;" [ngStyle]="{'color': isUserUpdated ? 'grey' : ''}">
      <div class="card-body border-bottom" >
        <div class="subtitle pb-2 pl-3"></div>
        <div class="icon-font-size-24"></div>
        <div class="mr-3 ml-3">
          <div class="d-flex justify-content-between mb-3">
            <div class="d-flex align-items-center">
              <div class="mr-1" *ngIf="this.userDetails?.insuranceCompanyCode">
                <img src="/assets/img/{{this.userDetails.insuranceCompanyCode}}.jpg" width="40px" class="td-img">
              </div>
              <div class="pl-1">
                <div class="font-weight-bold fz-18"> {{this.userDetails.firstName}} {{this.userDetails.lastName}} </div>
                <div class="fs-13">{{this.userDetails.email}}</div>
              </div>
            </div>
            <div class="d-flex align-items-center" *ngIf="this.status === 'update-user'">
              <button type="button" class="btn btn-primary m-1" (click)="this.activateOrDeactivateUser(this.userDetails)">
                <span *ngIf="userDetails.isUserActive"> Deaktiviraj uporabnika </span>
                <span *ngIf="!userDetails.isUserActive"> Aktiviraj uporabnika </span>
              </button>
              <button type="button" class="btn btn-primary m-1" (click)="updatePassword(this.userDetails)"> Zahtevaj spremembo gesla</button>
              <!-- <button class="btn btn-primary"
                      (click)="openHistory()"> Odpri zgodovino </button> -->
            </div>
          </div>
          <div class="d-flex justify-content-between pb-2">
            <div class="w-100 pr-3">
              <label class="small font-weight-bold" for="organisation"> Organizacija </label>
              <mat-select class="form-control" [ngClass]="[this.status === 'add-user' ? '' : ' mat-sel']"
                          formControlName="organisation">
                <mat-option *ngFor="let company of this.companies"
                            [value]="company?.insuranceCompanyCode"> {{company?.name}}
                </mat-option>
              </mat-select>
              <div *ngIf="form.dirty && form.controls['organisation'].errors?.['required'] " class="invalid-feedback">Organizacija
                je obvezna.
            </div>
            </div>
            <div class="w-100 pr-3">
              <label class="small font-weight-bold" for="firstName"> Ime </label>
              <input type="text" class="form-control" formControlName="firstName">
            </div>
            <div class="w-100 pr-3">
              <label class="small font-weight-bold" for="lastName"> Priimek </label>
              <input type="text" class="form-control" formControlName="lastName">
            </div>
            <div class="w-100 pr-3">
              <label class="small font-weight-bold" for="email"> E-mail </label>
              <input type="text" class="form-control" formControlName="email">
            </div>
            <div class="w-100">
              <label class="small font-weight-bold" for="phoneNumber"> Telefon </label>
              <input type="text" class="form-control" formControlName="phoneNumber">
            </div>
          </div>
          <div class="d-flex justify-content-between pb-2">
            <div class="w-100 pl-2 d-sm-none d-md-none d-xl-block d-lg-block small">
              <div *ngIf="this.validatorHelper.hasErrorAndTouched(form, 'organisation')">
                <ng-container [ngTemplateOutlet]="errorTemplate"
                              [ngTemplateOutletContext]="{key:'organisation'}"></ng-container>
              </div>
            </div>

            <div class="w-100 pl-2 d-sm-none d-md-none d-xl-block d-lg-block small">
              <div *ngIf="this.validatorHelper.hasErrorAndTouched(form, 'firstName')">
                <ng-container [ngTemplateOutlet]="errorTemplate"
                              [ngTemplateOutletContext]="{key:'firstName'}"></ng-container>
              </div>
            </div>

            <div class="w-100 pl-2 d-sm-none d-md-none d-xl-block d-lg-block small">
              <div *ngIf="this.validatorHelper.hasErrorAndTouched(form, 'lastName')">
                <ng-container [ngTemplateOutlet]="errorTemplate"
                              [ngTemplateOutletContext]="{key:'lastName'}"></ng-container>
              </div>
            </div>

            <div class="w-100 pl-2 d-sm-none d-md-none d-xl-block d-lg-block small">
              <div *ngIf="this.validatorHelper.hasErrorAndTouched(form, 'email')">
                <ng-container [ngTemplateOutlet]="errorTemplate"
                              [ngTemplateOutletContext]="{key:'email'}"></ng-container>
              </div>

              <div *ngIf="(form.invalid && form.touched) || form.dirty">
                <small *ngIf="form.controls['email'].errors?.['pattern']"
                       class="invalid-feedback">Vnesite veljaven elektronski naslov</small>
              </div>
            </div>

            <div class="w-100 pl-2 d-sm-none d-md-none d-xl-block d-lg-block small">
              <div *ngIf="this.validatorHelper.hasErrorAndTouched(form, 'phoneNumber')">
                <ng-container [ngTemplateOutlet]="errorTemplate"
                              [ngTemplateOutletContext]="{key:'phoneNumber'}"></ng-container>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between pb-2">
            <div class="w-100 pr-3">
              <label class="small font-weight-bold" for="userType"> Tip uporabnika </label>
              <mat-select class="form-control mat-sel" formControlName="userType">
                <mat-option *ngFor="let userType of this.userTypes"
                            [value]="this.userType?.key"> {{this.userType?.value}}
                </mat-option>
              </mat-select>
            </div>
            <div class="w-100 pr-3" *ngIf="this.userDetails?.lastActivity">
              <label class="small font-weight-bold" for="lastActivity"> Zadnja aktivnost </label>
              <input matInput
                    class="form-control"
                    bsDatepicker
                    formControlName="lastActivity"
                    [bsConfig]="{ dateInputFormat: 'DD.MM.YYYY', containerClass:'theme-orange', showWeekNumbers: false}">
            </div>
            <div class="w-100 pr-2" *ngIf="this.status === 'update-user'">
              <label class="small font-weight-bold"> Aktivnost uporabnika </label>
              <div *ngIf="this.userDetails"> {{this.convert.convertIsUserActive(this.userDetails.isUserActive)}}
                <i class="fas {{this.convert.convertUserActiveIcon(this.userDetails.isUserActive)}}"></i>
              </div>
            </div>

            <div class="w-100 pr-2" *ngIf="this.status === 'add-user'">
              <label class="small font-weight-bold"> Aktivnost uporabnika </label>
              <div> {{this.convert.convertIsUserActive(true)}}
                <i class="fas {{this.convert.convertUserActiveIcon(true)}}"></i>
              </div>
            </div>
            <div class="w-100 pr-3"></div>
            <div class="w-100 pr-3"></div>
          </div>

          <div class="d-flex justify-content-between">
            <div style="width: 885px !important;">
              <label class="small font-weight-bold" for="comment"> Komentar </label>
              <textarea type="text" class="form-control" formControlName="comment"></textarea>
            </div>
          </div>
          <div class="d-flex justify-content-between pb-2">
            <div class="w-100 pl-2 d-sm-none d-md-none d-xl-block d-lg-block small">
              <div *ngIf="this.validatorHelper.hasErrorAndTouched(form, 'comment')">
                <ng-container [ngTemplateOutlet]="errorTemplate"
                              [ngTemplateOutletContext]="{key:'comment'}"></ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body border-bottom" >
        <div class="mr-3 ml-3">
          <div class="d-flex align-items-center pb-3">
            <h4 class="font-weight-bold">Aplikacije & vloge</h4>
          </div>
            <div style="max-height: 60vh;display: flex;flex-wrap: wrap;flex-direction: column; overflow:auto;" *ngIf="this.groupedItems?.length">
                <div  *ngFor="let item of this.groupedItems" class="mr-3">
                  <h6 class="font-weight-bold" *ngIf="item[1].length > 0">{{item[0]}}</h6>
                  <ul class="ul-style">
                    <ng-container *ngFor="let item of this.item[1]">
                      <li>
                        <mat-checkbox class="example-margin checkbox-box" [(ngModel)]="item.isSelected"
                          [ngModelOptions]="{standalone: true}">
                          {{item.roleFriendlyName}}
                        </mat-checkbox>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>
          </div>
      </div>

      <div class="card-body border-bottom">
        <div class="d-flex justify-content-between mb-3">
          <div class="d-flex align-items-center pb-3">
            <h4 class="font-weight-bold ">Digitalna potrdila</h4>
          </div>
          <div class="d-flex align-items-center">
            <button type="button" class="btn btn-primary m-1" (click)="this.addDigitalCertificate()">
              <i class="fas fa-plus"></i> Dodaj potrdilo
            </button>
          </div>
        </div>

        <ng-container *ngIf="this.digitalCertificates">
          <szz-digital-certificate-table [digitalCertificates]="this.digitalCertificates" [ngStyle]="{'color': isUserUpdated ? 'grey' : ''}"
                                        [userId]="this.userId"
                                        (update)="updateDigitalCertificate($event)"
                                        (delete)="deleteDigitalCertificate($event)">
          </szz-digital-certificate-table>
        </ng-container>
      </div>
      <div class="pr-1 m-3" [ngStyle]="{'color': isUserUpdated ? 'grey' : ''}">
        <!-- <span *ngIf="isUserUpdated" class="d-flex justify-content-center pt-2"><img src="/assets/img/ajax-loader.gif" /></span> -->
        <span class="d-flex justify-content-end">
          <button class="btn btn-link m-1" type="button" (click)="goToUserPage()"> Zavrzi spremembe </button>
          <button class="btn btn-primary m-1" type="submit"> Shrani spremembe</button>
        </span>

      </div>

      <szz-loader [isUserUpdated]="isUserUpdated"></szz-loader>
    </div>

    <ng-template #errorTemplate let-key="key">
      <div *ngFor="let error of this.validatorHelper.getFormValidationErrors(form, key)"
           class="invalid-feedback"> {{error.value}} </div>
    </ng-template>
  </form>
</ng-container>

<szz-loader-search-in-progress [searchInProgress] = "searchInProgress"></szz-loader-search-in-progress>


