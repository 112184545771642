import { User } from '../../data/api/models/user';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UserService, CodeTableService } from 'src/app/data/api/services';
import { tap, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { InsuranceCompany } from '../../data/api/models/insurance-company';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DigitalCertificateComponent } from '../../modals/digital-certificate/digital-certificate.component';
import { CampaignHistoryComponent } from '../../modals/campaign-history/campaign-history.component';
import { Convert } from '../../common/convert';
import { RoleExtended } from '../../common/model/role-extended';
import { ArrayHelper } from '../../common/array-helper';
import { Role } from '../../data/api/models/role';
import { ActivateDeactivateComponent } from '../../modals/activate-deactivate/activate-deactivate.component';
import { ToastrService } from 'ngx-toastr';
import { StorageMap } from '@ngx-pwa/local-storage';
import { DigitalCertificate } from '../../data/api/models/digital-certificate';
import { Router } from '@angular/router';
import { SzzValidators } from '../../common/szz-validators';
import { ValidatorHelper } from '../../common/validator-helper';
import { VALIDATION_PATTERNS } from '../../common/_patterns';
import { UserCommonService } from '../../common/user-common.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: "szz-user-edit",
  templateUrl: "./user-edit.component.html",
  styleUrls: ["./user-edit.component.scss"],
})
export class UserEditComponent implements OnInit, OnDestroy {
  @Input() userId: string = "";
  @Input() status: string = "";

  form: UntypedFormGroup = new UntypedFormGroup({});
  modalRef: BsModalRef;
  companies: Array<InsuranceCompany> = [];
  digitalCertificates: DigitalCertificate[] | any = [];
  dig: DigitalCertificate = {};
  userDetails: User = {};

  addOrUpdateUserSub$: Subscription | undefined;
  activateOrDeactibateUserSub$: Subscription | undefined;
  userDetailsSub$: Subscription | undefined;

  userTypes = this.arrayHelper.getUserTypes();

  searchInProgress = false;
  exist = false;
  isUserActive: boolean | null | undefined = true;

  digCerStatus: string = "";
  isUserUpdated = false;
  groupedItems: any[] = [];

  constructor(
    private userService: UserService,
    private modalService: BsModalService,
    public convert: Convert,
    private toastr: ToastrService,
    private storage: StorageMap,
    public arrayHelper: ArrayHelper,
    private router: Router,
    public validatorHelper: ValidatorHelper,
    private codeTableService: CodeTableService,
    private userCommonService: UserCommonService
  ) {
    this.initForm();
    this.modalRef = new BsModalRef();
  }

  ngOnInit(): void {
    this.userCommonService.setDigCerStatus("empty");

    this.getAvailableCompaniesForUser();
    if (this.status === "update-user") {
      this.getUserDetails();
    } else if (this.status === "add-user") {
      this.resetUserRoles();
      this.searchInProgress = false;
      this.storage.clear().subscribe({
        next: () => {},
        error: (error) => {},
      });
    }

    this.codeTableService
      .AllowedRoles({})
      .pipe(
        tap((roles) => {
          var items = new Map<string | null | undefined, Role[]>();
          roles.forEach((item) => {
            if (!items?.has(item.applicationFriendlyName)) {
              items?.set(item.applicationFriendlyName, []);
            }
            items?.get(item.applicationFriendlyName)?.push(item);
          });
          this.groupedItems = Array.from(items.entries());
        })
      )
      .subscribe({
        next: (v) => {},
        error: (e) => {
          throw e;
        },
      });
  }

  initForm(userDetails?: User) {
    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl(
        userDetails?.firstName ? userDetails?.firstName : "",
        [SzzValidators.maxLengthValidator(30, "Vnesite največ 30 znakov!")]
      ),
      lastName: new UntypedFormControl(
        userDetails?.lastName ? userDetails?.lastName : "",
        [SzzValidators.maxLengthValidator(50, "Vnesite največ 50 znakov!")]
      ),
      email: new UntypedFormControl(
        {
          value: userDetails?.email ? userDetails?.email : "",
          disabled: userDetails?.email ? true : false,
        },
        [
          SzzValidators.maxLengthValidator(50, "Vnesite največ 50 znakov!"),
          Validators.pattern(VALIDATION_PATTERNS.EMAIL),
        ]
      ),
      organisation: new UntypedFormControl(
        {
          value: userDetails?.insuranceCompanyCode
            ? userDetails?.insuranceCompanyCode
            : "",
          disabled: true,
        },
        [Validators.required]
      ),
      phoneNumber: new UntypedFormControl(
        userDetails?.phoneNumber ? userDetails?.phoneNumber : "",
        [SzzValidators.maxLengthValidator(50, "Vnesite največ 50 znakov!")]
      ),
      userType: new UntypedFormControl({
        value: userDetails?.userType ? "INTERNAL_USER" : "",
        disabled: true,
      }),
      lastActivity: new UntypedFormControl({
        value: userDetails?.lastActivity
          ? new Date(userDetails?.lastActivity)
          : "",
        disabled: true,
      }),
      comment: new UntypedFormControl(
        userDetails?.comment ? userDetails?.comment : "",
        [SzzValidators.maxLengthValidator(200, "Vnesite največ 200 znakov!")]
      ),
    });
  }

  updateDigitalCertificate(event: any) {
    if (event.isUpdated && this.status === "update-user") {
      this.getUserDetails();
    }
  }

  deleteDigitalCertificate(event: any) {
    if (event.isDeleted) {
      this.digitalCertificates = this.userCommonService.getDigCertificates();
    }
  }

  activateOrDeactivateUser(userDetails: User) {
    let messageForModal = "Želite aktivirati uporabnika?";
    let titleForModal = "Aktivacija uporabnika";

    if (userDetails.isUserActive) {
      messageForModal = "Želite deaktivirati uporabnika?";
      titleForModal = "Deaktivacija uporabnika";
    }

    this.modalRef = this.modalService.show(ActivateDeactivateComponent, {
      class: "modal-md",
      initialState: {
        data: {
          title: titleForModal,
          okLabel: "Potrdi",
          closeLabel: "Prekini",
          message: messageForModal,
        },
      },
    });

    let subscriptionActivate = this.modalService.onHide.subscribe((x) => {
      if (x === "OK") {
        this.isUserUpdated = true;
        this.userCommonService.setIsUserUpdated(this.isUserUpdated);
        if (userDetails.userId && userDetails.isUserActive !== null) {
          const params: UserService.ActivateOrDeactivateUserParams = {
            isUserActive: !userDetails.isUserActive,
            userId: userDetails.userId,
          };

          this.activateOrDeactibateUserSub$ = this.userService
            .activateOrDeactivateUser(params)
            .pipe(
              take(1),
              tap((x) => {
                this.isUserUpdated = true;
                this.isUserActive = x.isUserActive;
                this.userCommonService.setIsUserUpdated(this.isUserUpdated);

                this.userDetails = x;
                if (this.userDetails.isUserActive) {
                  const toast = this.toastr.success(
                    "Uspešno ste aktivirali uporabnika!",
                    "Status akcije",
                    {
                      closeButton: true,
                      progressBar: true,
                      positionClass: "toast-bottom-right",
                    }
                  );

                  toast.onShown.subscribe((action) => {
                    this.isUserUpdated = false;
                    this.userCommonService.setIsUserUpdated(this.isUserUpdated);
                  });
                } else {
                  const toast = this.toastr.success(
                    "Uspešno ste deaktivirali uporabnika!",
                    "Status akcije",
                    {
                      closeButton: true,
                      progressBar: true,
                      positionClass: "toast-bottom-right",
                    }
                  );
                  toast.onShown.subscribe((action) => {
                    this.isUserUpdated = false;
                    this.userCommonService.setIsUserUpdated(this.isUserUpdated);
                  });
                }
              })
            )
            .subscribe({
              next: (v) => {},
              error: (e) => {
                throw e;
              },
            });
        }
        subscriptionActivate.unsubscribe();
      } else {
        subscriptionActivate.unsubscribe();
      }
    });
  }

  getUserDetails() {
    this.searchInProgress = true;
    this.userDetailsSub$ = this.userService
      .userDetails({ userId: Number(this.userId) })
      .pipe(
        tap((x) => {
          this.userDetails = x;
          this.isUserActive = this.userDetails.isUserActive;
          this.initForm(this.userDetails);
          this.digCerStatus = this.userCommonService.getDigCerStatus();
          if (this.digCerStatus === "update-digital-certificate") {
            this.digitalCertificates =
              this.userCommonService.getDigCertificates();
          } else {
            this.digitalCertificates = this.userDetails.digitalCertificates;
            this.userCommonService.setDigCertificates(this.digitalCertificates);
          }
          if (this.userDetails && this.userDetails.roles) {
            this.selectedUserRole();
          }
          this.searchInProgress = false;
        })
      )
      .subscribe({
        next: (v) => {},
        error: (e) => {
          throw e;
        },
      });
  }

  submit() {
    this.form.markAllAsTouched();
    this.form.markAsDirty();
    if (this.form.valid) {
      if (
        this.digitalCertificates.filter(
          (cert: DigitalCertificate) => !cert.isDisabled
        ).length > 1
      ) {
        this.toastr.error(
          "Uporabnik ima lahko največ 1 aktiven certifikat naenkrat.",
          "Status akcije",
          {
            closeButton: true,
            progressBar: true,
            positionClass: "toast-bottom-right",
          }
        );
        return;
      }

      let formValues = this.form.getRawValue();

      let rolesForSubmit: Role[] = [];
      this.manipulateWithRoleArray(rolesForSubmit);

      let request: User = {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        insuranceCompanyCode: formValues.organisation,
        email: formValues.email,
        applications: [],
        phoneNumber: formValues.phoneNumber,
        comment: formValues.comment,
        userType: formValues.userType,
        isUserActive: this.isUserActive,
        lastActivity: formValues.lastActivity,
        roles: rolesForSubmit,
        digitalCertificates: this.digitalCertificates,
      };

      this.isUserUpdated = true;
      this.userCommonService.setIsUserUpdated(this.isUserUpdated);

      this.addOrUpdateUserSub$ = this.userService
        .addOrUpdateUser({ body: request })
        .pipe(
          take(1),
          tap((userId) => {
            this.isUserUpdated = true;
            this.userCommonService.setIsUserUpdated(this.isUserUpdated);
            const toast = this.toastr.success(
              "Akcija uspešno zaključena!",
              "Status akcije",
              {
                closeButton: true,
                progressBar: true,
                positionClass: "toast-bottom-right",
              }
            );

            toast.onShown.subscribe((action) => {
              this.isUserUpdated = false;
              this.userCommonService.setIsUserUpdated(this.isUserUpdated);
            });
          })
        )
        .subscribe({
          next: (v) => {
            if (v) {
              this.router.navigate(["/user-details", v]);
            }
          },
          error: (e) => {
            this.userCommonService.setIsUserUpdated(this.isUserUpdated);
            this.isUserUpdated = false;
            throw e;
          },
        });
    }
  }

  addDigitalCertificate() {
    this.exist = false;
    this.modalRef = this.modalService.show(DigitalCertificateComponent, {
      class: "modal-md",
      initialState: {
        data: {
          userId: this.userId,
          digitalCertificate: {},
          status: "insert",
        },
      },
    });

    let subscriptionActivate = this.modalService.onHide.subscribe((x) => {
      if (x == "SAVE_RESULT") {
        let digCer = this.userCommonService.getDigCertificate();
        if (this.digitalCertificates === undefined) {
          this.digitalCertificates = [];
        }
        this.exist = this.digitalCertificates.some(
          (cer: DigitalCertificate | any) =>
            cer.serialNumber === digCer.serialNumber &&
            cer.publisher === digCer.publisher
        );

        if (this.digitalCertificates.length >= 3) {
          // this.isUserUpdated = true;
          const toast = this.toastr.error(
            "Uporabnik ima lahko največ 3 digitalna potrdila",
            "Status akcije",
            {
              closeButton: true,
              progressBar: true,
              positionClass: "toast-bottom-right",
            }
          );
          // toast.onHidden.subscribe((action) =>  this.isUserUpdated = false )
        } else {
          if (this.exist) {
            //  this.isUserUpdated = true;
            const toast = this.toastr.warning(
              "To digitalno potrdilo je že prijavljeno za delo z aplikacijo!",
              "Status akcije",
              {
                closeButton: true,
                progressBar: true,
                positionClass: "toast-bottom-right",
              }
            );
            // toast.onHidden.subscribe((action) =>  this.isUserUpdated = false )
          } else {
            this.digitalCertificates.push(digCer);
          }
        }
        this.userCommonService.setDigCertificates(this.digitalCertificates);
        subscriptionActivate.unsubscribe();
      } else {
        subscriptionActivate.unsubscribe();
      }
    });
  }

  openHistory() {
    this.modalRef = this.modalService.show(CampaignHistoryComponent, {
      class: "modal-md",
      initialState: {
        data: {
          okLabel: "Zapri",
          closeLabel: "Prekini",
        },
      },
    });

    let subscriptionActivate = this.modalService.onHide.subscribe((x) => {
      if (x === "OK") {
        subscriptionActivate.unsubscribe();
      } else {
        subscriptionActivate.unsubscribe();
      }
    });
  }

  updatePassword(userDetails?: User) {
    if (userDetails && userDetails?.userId) {
      const params: UserService.UpdateUserPasswordParams = {
        userId: userDetails?.userId,
      };

      this.userService.updateUserPassword(params).subscribe({
        next: (v) => {
          this.toastr.success("Akcija uspešno zaključena!", "Status akcije", {
            closeButton: true,
            progressBar: true,
            positionClass: "toast-bottom-right",
          });
        },
        error: (e) => {
          throw e;
        },
      });
    }
  }

  getAvailableCompaniesForUser() {
    this.userService
      .AvailableCompaniesForUser({})
      .pipe(
        tap((x) => {
          this.companies = x;
          if (this.companies.length > 1) {
            this.form.controls["organisation"].enable();
          } else {
            // this.form.controls["organisation"].disable();
            this.form.patchValue({
              organisation: this.companies[0]?.insuranceCompanyCode,
            });
          }
        })
      )
      .subscribe({
        next: (v) => {},
        error: (e) => {
          throw e;
        },
      });
  }

  manipulateWithRoleArray(rolesForSubmit: Role[]) {
    this.groupedItems.forEach((gi) => {
      let newRoleArray = gi[1].filter(
        (role: RoleExtended) => role.isSelected === true
      );
      if (newRoleArray.length > 0) {
        newRoleArray.forEach((role: RoleExtended) => {
          rolesForSubmit.push({
            appliationName: gi[0],
            roleName: role.roleName,
          });
        });
      }
    });
  }

  selectedUserRole() {
    this.groupedItems.forEach((gi) => {
      gi[1].forEach((role: RoleExtended) => {
        role.isSelected = this.userDetails.roles?.some(
          (r) => r.roleName == role.roleName
        );
      });
    });
  }

  resetUserRoles() {
    this.groupedItems.forEach((gi) => {
      gi[1].forEach((value: RoleExtended) => {
        value.isSelected = false;
      });
    });
  }

  toggleCheckState(event: MouseEvent, item: RoleExtended) {
    event.preventDefault();
    item.isSelected = !item.isSelected;
  }

  goToUserPage() {
    this.form.reset();
    this.router.navigate(["/"]);
  }

  ngOnDestroy(): void {
    this.userDetailsSub$?.unsubscribe();
    this.addOrUpdateUserSub$?.unsubscribe();
    this.activateOrDeactibateUserSub$?.unsubscribe();
  }
}