import { Component, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { AuthService, ProfileService } from 'src/app/data/api/services';
import { ProfileViewModel } from 'src/app/data/api/models';
import { StorageMap } from '@ngx-pwa/local-storage';

@Component({
  selector: 'szz-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent implements OnInit {
  collapsed: boolean = true;
  showBackButton: boolean = false;
  window;
  isAuthenticated: boolean = false;
  collapsedCodes: boolean = true;
  canSearchForPolicy: boolean = true;
  canSearchForClaimFile: boolean = true;
  isOpened = false;
  model: ProfileViewModel = {};

  constructor(
    private location: Location,
    private profileService: ProfileService,
    private authService: AuthService,
    private storage: StorageMap,
  ) {
    this.window = window;
  }

  ngOnInit() {
    this.storage.clear().subscribe({});

    this.authService.isAuthenticated({}).subscribe((res) => {
      if (res) {
        this.isAuthenticated = true;
        this.profileService.Self({})
          .pipe(
            tap((x) => {
              this.model = x;
              if (this.model && this.model.fullName) {
                this.model.fullName = this.model.fullName.trim();
              } else {
                this.model.fullName = 'Name Undefined';
              }
            })
          ).subscribe();
      } else {
        this.isAuthenticated = false;
        window.location.replace('/api/auth/login?returnUrl=' + encodeURIComponent(window.location.href));
      }
    });
  }

  goBack() {
    this.location.back();
  }

  onChangeItemMenu(status: string) {
    if (status === 'organisations') {
      this.collapsedCodes = !this.collapsedCodes;
    }else if (status === 'add-new-user') {
      this.collapsedCodes = !this.collapsedCodes;
    }
  }

  downloadPdf(){
    window.location.href='/assets/docs/administracija-uporabnikov-prirocnik.pdf';
  }
}
