import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'szz-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
})
export class DeleteComponent implements OnInit {
  data = {
    okLabel: '',
    closeLabel: '',
    message: '',
  };

  constructor(private modalService: BsModalService) {}

  ngOnInit(): void {}

  confirm(): void {
    this.modalService.setDismissReason('OK');
    this.modalService.hide();
  }

  close() {
    this.modalService.setDismissReason('CLOSE');
    this.modalService.hide();
  }
}
