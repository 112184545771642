import { Component, OnInit } from '@angular/core';
import { ErrorService } from 'src/app/common/error.service';

@Component({
  selector: 'szz-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  public message: string;
  public errorCode: number;
  constructor(private errorService: ErrorService) {
    this.errorCode = this.errorService.getErrorCode();
    this.message = this.errorService.getErrorMessage();
  }

  ngOnInit(): void {
  }

}
