<div class="card overflow-hidden">
  <div class="table-responsive">
    <table class="table table-bordered small mb-0">
      <thead class="bg-primary text-white">
        <tr class="bg-primary text-center">
          <th scope="col" class="font-weight-normal">Ime in priimek</th>
          <th scope="col" class="font-weight-normal">E-mail</th>
          <th scope="col" class="font-weight-normal">Organizacija</th>
          <th scope="col" class="font-weight-normal">Aplikacija</th>
          <th scope="col" class="font-weight-normal"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of pageDataArray"  class="text-center" [ngStyle]="{'color': !this.user?.isUserActive ? 'grey' : ''}">
          <td>
            <span *ngIf="!this.user?.isUserActive" [tooltip]="showUserActivity" placement="bottom">
              <i class="fas fa-exclamation-circle status-refused"></i>
            </span>
            <ng-template #showUserActivity> {{this.convert.convertIsUserActive(this.user?.isUserActive)}}</ng-template>
            {{user.firstName}} {{user.lastName}}
          </td>
          <td>{{user.email}}</td>
          <td><img src="/assets/img/{{user.insuranceCompanyCode}}.jpg" width="20px" class="td-img"> {{user.insuranceCompanyName}}</td>
          <td>
            <div *ngIf="(user?.applications?.length ?? 0) > 0">
              <ng-container *ngFor="let application of user?.applications">
                <span class="mr-1" [ngClass]="[!this.user?.isUserActive ? ' app-box-grey' : ' app-box']">
                  {{application}}
                </span>
              </ng-container>
            </div>
          </td>
          <td class="user-details-btn">
            <span class="edit-btn">
              <i class="fas fa-pen-square" (click)="this.goToUserDetails(user)"></i>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row justify-content-md-end pb-0 pt-3 pr-3">
  <szz-pagination [items]="users" (changePage)="onChangePage($event)"></szz-pagination>
</div>

