import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UserDetailsPageComponent } from './user-details-page/user-details-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BootstrapModule } from '../../common/bootstrap.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ComponentsModule } from '../../components/components.module';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [UserDetailsPageComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatListModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    BootstrapModule,
    ComponentsModule,
    ButtonsModule.forRoot(),
  ],
  exports: [UserDetailsPageComponent, ComponentsModule],
})
export class UserDetailsModule {}
