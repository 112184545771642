import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DeleteComponent } from './delete/delete.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DigitalCertificateComponent } from './digital-certificate/digital-certificate.component';
import { CampaignHistoryComponent } from './campaign-history/campaign-history.component';
import { BootstrapModule } from '../common/bootstrap.module';
import { ActivateDeactivateComponent } from './activate-deactivate/activate-deactivate.component';
import { ErrorToastrComponent } from './error-toastr/error-toastr.component';
import { InactivityComponent } from './inactivity/inactivity.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    DigitalCertificateComponent,
    DeleteComponent,
    CampaignHistoryComponent,
    ActivateDeactivateComponent,
    ErrorToastrComponent,
    InactivityComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgbModule,
    RouterModule,
    MatCheckboxModule,
    MatListModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    BootstrapModule,
  ],
  exports: [
    DigitalCertificateComponent,
    DeleteComponent,
    CampaignHistoryComponent,
    ActivateDeactivateComponent,
    ErrorToastrComponent,
    InactivityComponent
  ]
})
export class ModalsModule { }
