<div *ngIf="this.isAuthenticated" class="main-wraper">
  <nav class="navbar navbar-default  navbar-static-top navbar-szz"
      role="navigation"
      style="margin-bottom: 0">
    <div class="navbar-header">
      <a class="navbar-brand" href="index.html"><img src="/assets/img/szz_logo-inverse.svg"  height="45" /></a>
    </div>
    <!-- /.navbar-header -->
    <ul class="nav nav-szz-apps navbar-top-links navbar-right pb-3">
      <li class="dropdown" dropdown>
        <a class="dropdown-toggle user-account" data-toggle="dropdown" href="javascript: void(0)" dropdownToggle>
          <i class="fa fa-user fa-fw" style=" color: #fff;"></i> {{model.fullName}} <i class="fa fa-caret-down"></i>
        </a>
        <ul class="dropdown-menu dropdown-menu-right dropdown-user" *dropdownMenu>
          <!-- <li><a href="#"><i class="fa fa-user fa-fw"></i>Uporabniški profil</a>
          </li>
          <li><a href="#"><i class="fa fa-gear fa-fw"></i> Nastavitve</a>
          </li> -->
          <!-- <li class="divider"></li> -->

          <li class="border-bottom">
            <a href="/mojszz">
            <i class="fas fa-home pl-3 pr-3  "style="font-size: 20px; color: #8e4058;"></i>
              Moj SZZ
            </a>
          </li>
          <li>
            <a href="/api/auth/logout?returnUrl={{window.location.href}}">
            <i class="fas fa-sign-out-alt pl-3 pr-3" style="font-size: 20px; color: #8e4058;"></i>
              Odjava
            </a>
          </li>
        </ul>
        <!-- /.dropdown-user -->
      </li>
      <!-- /.dropdown -->
    </ul>
    <!-- /.navbar-top-links -->
  </nav>
  <div id="wrapper">
    <!-- Sidebar -->
    <div class="sidebar d-flex align-content-between flex-wrap">
      <div>
        <div><a class="hide-navigation" alt="Skrij Navigacijo"><i class="fa fa-thumb-tack"></i></a></div>
        <div class="sidebar-header">
          <h6 class="app-name mt-3 d-none d-lg-block">Administracija uporabnikov</h6>
          <h1 class="app-name mt-3 mb-0 d-lg-none ml-md-1"> <i class="fa fa-user"></i></h1>
        </div>
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a class="nav-link pb-0"
                id="pagesDropdown1"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                routerLink="/"
                (click)="onChangeItemMenu('organisations')">
                <i class="fas fa-users mr-lg-2"></i><span class="d-none d-lg-inline">Pregled uporabnikov</span>
              </a>
            </li>

            <li class="nav-item dropdown">
              <a class="nav-link"
                 id="pagesDropdown1"
                 role="button"
                 data-toggle="dropdown"
                 aria-haspopup="true"
                 aria-expanded="false"
                 routerLink="/add-user"
                 (click)="onChangeItemMenu('add-new-user')">
                 <i class="fas fa-user-plus mr-lg-2"></i><span class="d-none d-lg-inline">Nov uporabnik</span>
              </a>
            </li>
          </ul>

          <!-- <ul class="navbar-nav">

          </ul> -->
        </div>
    </div>
    <div class="mr-lg-2 docs-design">
      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <a class="nav-link-bottom"
            download="administracija-uporabnikov-prirocnik.pdf"
            id="pagesDropdown2"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            href="assets/docs/administracija-uporabnikov-prirocnik.pdf">
            <i class="fas fa-exclamation-circle mr-lg-2 yellow-color"></i>
            <span class="d-none d-lg-inline">Uporabniški priročnik</span>
          </a>
        </li>
      </ul>
    </div>

    <div id="content-wrapper"
        class="pt-0">
      <div class="page-header p-0">
        <a *ngIf="showBackButton" class="nav-link navbar-right pb-3" href="javascript:void(0)" (click)="goBack();">
          <h5 class="app-name ml-5 font-weight-light"><i class="fa fa-chevron-left"></i> Nazaj</h5>
        </a>
      </div>
      <router-outlet></router-outlet>
      <!-- /.container-fluid -->
      <!-- Sticky Footer -->
      <footer class="sticky-footer">
        <div class="container my-auto">
          <div class="copyright text-center my-auto">
            <span>Copyright © SZZ 2020-2021</span>
          </div>
        </div>
      </footer>
    </div>
    <!-- /.content-wrapper -->
  </div>
  <!-- /#wrapper -->
  <!-- Scroll to Top Button-->
  <a class="scroll-to-top rounded" href="#page-top">
    <i class="fas fa-angle-up"></i>
  </a>
  <!-- Logout Modal-->
  <div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"
              id="exampleModalLabel">Ready to Leave?</h5>
          <button class="close" type="button" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">Select "Logout" below if you are ready to end your current session.</div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
          <a class="btn btn-primary" href="login.html">Logout</a>
        </div>
      </div>
    </div>
  </div>
</div>
