import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../../data/api/models/user';
import { tap } from 'rxjs/operators';
import { UserCommonService } from '../../../common/user-common.service';
import { UserService } from 'src/app/data/api/services';

@Component({
  selector: 'szz-user-details-page',
  templateUrl: './user-details-page.component.html',
  styleUrls: ['./user-details-page.component.scss'],
})
export class UserDetailsPageComponent implements OnInit {
  userId: string = '';

  status = 'update-user';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private userCommonService: UserCommonService
  ) {
    this.userId = this.route.snapshot.params['id'];
    if (!this.userId) {
      this.status = 'add-user';
    }
  }

  ngOnInit(): void {}

  goBack() {
    let request = this.userCommonService.getUserReq();

    this.userService
      .userSearch(request)
      .pipe(
        tap((x) => {
          this.userCommonService.setUsers(x);
          this.router.navigateByUrl('/');
        })
      )
      .subscribe({
        next: (v) => {},
        error: (e) => {
          throw e;
        },
      });
  }
}
