/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class AuthService extends __BaseService {
  static readonly getAuthPath = '/auth';
  static readonly getAuthAccessdeniedPath = '/auth/accessdenied';
  static readonly postAuthAccessdeniedPath = '/auth/accessdenied';
  static readonly putAuthAccessdeniedPath = '/auth/accessdenied';
  static readonly getAuthClaimsPath = '/auth/claims';
  static readonly getAuthIndexPath = '/auth/index';
  static readonly isAuthenticatedPath = '/auth/isauthenticated';
  static readonly getAuthLoginPath = '/auth/login';
  static readonly getAuthLogoutPath = '/auth/logout';
  static readonly getAuthRefreshcsrftokenPath = '/auth/refreshcsrftoken';
  static readonly getAuthTestnoauthPath = '/auth/testnoauth';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AuthService.GetAuthParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  getAuthResponse(params: AuthService.GetAuthParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/auth`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AuthService.GetAuthParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  getAuth(params: AuthService.GetAuthParams): __Observable<null> {
    return this.getAuthResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AuthService.GetAuthAccessdeniedParams` containing the following parameters:
   *
   * - `msg`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  getAuthAccessdeniedResponse(params: AuthService.GetAuthAccessdeniedParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.msg != null) __params = __params.set('msg', params.msg.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/auth/accessdenied`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AuthService.GetAuthAccessdeniedParams` containing the following parameters:
   *
   * - `msg`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  getAuthAccessdenied(params: AuthService.GetAuthAccessdeniedParams): __Observable<null> {
    return this.getAuthAccessdeniedResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AuthService.PostAuthAccessdeniedParams` containing the following parameters:
   *
   * - `msg`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  postAuthAccessdeniedResponse(params: AuthService.PostAuthAccessdeniedParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.msg != null) __params = __params.set('msg', params.msg.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/auth/accessdenied`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AuthService.PostAuthAccessdeniedParams` containing the following parameters:
   *
   * - `msg`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  postAuthAccessdenied(params: AuthService.PostAuthAccessdeniedParams): __Observable<null> {
    return this.postAuthAccessdeniedResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AuthService.PutAuthAccessdeniedParams` containing the following parameters:
   *
   * - `msg`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  putAuthAccessdeniedResponse(params: AuthService.PutAuthAccessdeniedParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.msg != null) __params = __params.set('msg', params.msg.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/auth/accessdenied`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AuthService.PutAuthAccessdeniedParams` containing the following parameters:
   *
   * - `msg`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  putAuthAccessdenied(params: AuthService.PutAuthAccessdeniedParams): __Observable<null> {
    return this.putAuthAccessdeniedResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AuthService.GetAuthClaimsParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  getAuthClaimsResponse(params: AuthService.GetAuthClaimsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/auth/claims`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AuthService.GetAuthClaimsParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  getAuthClaims(params: AuthService.GetAuthClaimsParams): __Observable<null> {
    return this.getAuthClaimsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AuthService.GetAuthIndexParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  getAuthIndexResponse(params: AuthService.GetAuthIndexParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/auth/index`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AuthService.GetAuthIndexParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  getAuthIndex(params: AuthService.GetAuthIndexParams): __Observable<null> {
    return this.getAuthIndexResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AuthService.IsAuthenticatedParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return The result is returned.
   */
  isAuthenticatedResponse(params: AuthService.IsAuthenticatedParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/auth/isauthenticated`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `AuthService.IsAuthenticatedParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return The result is returned.
   */
  isAuthenticated(params: AuthService.IsAuthenticatedParams): __Observable<boolean> {
    return this.isAuthenticatedResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `AuthService.GetAuthLoginParams` containing the following parameters:
   *
   * - `returnUrl`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  getAuthLoginResponse(params: AuthService.GetAuthLoginParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.returnUrl != null) __params = __params.set('returnUrl', params.returnUrl.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/auth/login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AuthService.GetAuthLoginParams` containing the following parameters:
   *
   * - `returnUrl`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  getAuthLogin(params: AuthService.GetAuthLoginParams): __Observable<null> {
    return this.getAuthLoginResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AuthService.GetAuthLogoutParams` containing the following parameters:
   *
   * - `returnUrl`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  getAuthLogoutResponse(params: AuthService.GetAuthLogoutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.returnUrl != null) __params = __params.set('returnUrl', params.returnUrl.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/auth/logout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AuthService.GetAuthLogoutParams` containing the following parameters:
   *
   * - `returnUrl`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  getAuthLogout(params: AuthService.GetAuthLogoutParams): __Observable<null> {
    return this.getAuthLogoutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AuthService.GetAuthRefreshcsrftokenParams` containing the following parameters:
   *
   * - `returnUrl`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  getAuthRefreshcsrftokenResponse(params: AuthService.GetAuthRefreshcsrftokenParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.returnUrl != null) __params = __params.set('returnUrl', params.returnUrl.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/auth/refreshcsrftoken`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AuthService.GetAuthRefreshcsrftokenParams` containing the following parameters:
   *
   * - `returnUrl`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  getAuthRefreshcsrftoken(params: AuthService.GetAuthRefreshcsrftokenParams): __Observable<null> {
    return this.getAuthRefreshcsrftokenResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AuthService.GetAuthTestnoauthParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  getAuthTestnoauthResponse(params: AuthService.GetAuthTestnoauthParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/auth/testnoauth`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AuthService.GetAuthTestnoauthParams` containing the following parameters:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   */
  getAuthTestnoauth(params: AuthService.GetAuthTestnoauthParams): __Observable<null> {
    return this.getAuthTestnoauthResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AuthService {

  /**
   * Parameters for getAuth
   */
  export interface GetAuthParams {

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for getAuthAccessdenied
   */
  export interface GetAuthAccessdeniedParams {
    msg?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for postAuthAccessdenied
   */
  export interface PostAuthAccessdeniedParams {
    msg?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for putAuthAccessdenied
   */
  export interface PutAuthAccessdeniedParams {
    msg?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for getAuthClaims
   */
  export interface GetAuthClaimsParams {

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for getAuthIndex
   */
  export interface GetAuthIndexParams {

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for isAuthenticated
   */
  export interface IsAuthenticatedParams {

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for getAuthLogin
   */
  export interface GetAuthLoginParams {
    returnUrl?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for getAuthLogout
   */
  export interface GetAuthLogoutParams {
    returnUrl?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for getAuthRefreshcsrftoken
   */
  export interface GetAuthRefreshcsrftokenParams {
    returnUrl?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for getAuthTestnoauth
   */
  export interface GetAuthTestnoauthParams {

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }
}

export { AuthService }
